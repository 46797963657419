__foundationRun = true;

function foundationUpdate(el) {
    if (__foundationRun) {
        if (el.data('zfPlugin'))
            // existing element already has a plugin - update
            Foundation.reInit(el);
        else
            // new element - initialize
            el.foundation();
    }
    // else leave for foundation to init
}